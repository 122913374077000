.announcement-types {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .types-sidebar {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1%;
        margin-left: 7%;
        width: 91%;

        button {
            font-size: 1rem;
            // background-color: transparent;
            background-color: $secundary-color;
            border: 0;
            color: $grey;
            padding: .7rem;
            border-radius: 10px;
            transition: 0.2s;
            cursor: pointer;
        }

        button.active {
            color: $secundary-color;
        }

        button:hover {
            opacity: 0.9;
        }

        button+button {
            margin-left: 1rem;
        }
    }

    .types-content {
        /* background-color: #fff;
    padding: 2rem; */
        border-radius: 10px;
        width: 100%;
        height: 100%;
        // margin-right: 3%;

        h1 {
            color: $bg-color;
        }

        a {
            text-decoration: none;
        }
    }
}

/*
@media (max-width: 800px) {
    .announcements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 6%;
    }

    .announcements-sidebar {
        margin-right: 0;
        justify-content: center;

        button {
            margin: 0.2rem 0;
        }
        button {
            text-align: center;
        }
        button + button {
            margin-left: 0;
        }
    }

    .announcements-content {
        width: 100%;
        margin-right: 2%;
        margin-top: 1rem;
    }
}

@media (max-width: 700px) {
    .announcements {
        margin: 0 2rem;
    }

    .announcements-sidebar {
        margin-bottom: 0;

        button {
            margin: 0.2rem 0;
        }
    }
}
*/