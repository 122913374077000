header {
    display: flex;
    align-items: center;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    width: 96%;
    height: 5rem;
    padding-left: 2rem;
    padding-right: 4rem;
    margin-bottom: 1rem;
    margin-left: 4%;
    background-color: $light;

    div.header-title {
        display: flex;
        max-width: 500px;
        width: 100%;
        align-items: center;
        background-color: $secundary-color;
        border-radius: 8px;
        padding: 4px 12px;
        box-shadow: 0px 0px 4px 0px $grey;

        path {
            color: $bg-color;
        }
    }

    input {
        width: 100%;
        border-radius: 8px;
        height: 30px;
        border: none;
        padding: 10px;

    }

    svg.icon-menu {
        cursor: pointer;
        color: $bg-color;
        margin-right: 15px;
        margin-left: 3px;
    }

    h2 {
        font-size: 2rem;
        color: $bg-color;
        font-weight: normal;
    }

    b {
        font-weight: bold;
    }

    .icons {
        display: flex;
        justify-content: center;
        border-left: 2px solid $secondary-light;
        height: 100%;
        align-items: center;
        padding: 0px 24px;
        gap: 6px;

        div.icon-down {
            margin-left: 18px;
        }


        div.setting-title-div {
            padding: 10px;

            .div-name {
                color: $bg-color;
                font-weight: bold;
            }
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 1px;
            border: 2px solid $bg-color;
        }

        button {
            height: 27px;
            width: 25px;
            border-radius: 4px;
            border: 0px solid $secondary-light;
            background: transparent;
            margin-left: 16px;
            transition: border-color 0.2s;
            cursor: pointer;
        }

        button:hover {
            border-color: #999;
        }
    }

    .upper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-right: 12px;

        #impersonateButton {
            border-radius: 4px;
            border: 0px;
            background: $bg-color;
            color: $secundary-color;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin-left: 2rem;
            padding: 0.7rem;
        }

        #impersonateButton:hover {
            color: $bg-color;
            background: $secundary-color;
            border: 1px solid $bg-color;
            cursor: pointer;
        }
    }
}

header.sidebar-open {
    padding-left: 150px;

}

@media (max-width: 700px) {
    header {
        display: none;
    }
}