.requests-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 6%;

    h2 {
        font-size: 2rem;
        color: $bg-color;
        font-weight: 900;
        margin: 2rem 0;
    }

    h2 b {
        padding: 0.5rem 0.7rem;
        background: $bg-color;
        border-radius: 10px;
        color: #fff;
    }

    button {
        height: 4rem;
        width: 15%;
        border-radius: 10px;
        border: 0px;
        background: #25ab9f;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        transition: 0.2s;
        margin: 0.7rem 0;
    }

    .thiscontent {
        button {
            height: 2rem;
            width: 10%;
            border-radius: 20px;
            border: 0px;
            background: #25ab9f;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin: 0.7rem 2rem;
        }

        button:hover {
            opacity: 0.5;
        }

        h1 {
            font-size: 1.5rem;
            font-weight: 400;
        }

        h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4rem;
            width: 20%;
            margin: 1rem 0;
            border-radius: 10px;
            border: 0px;
            background: $bg-color;
            color: #fff;
            font-size: 2rem;
            font-weight: bold;
        }

        ul {
            list-style: none;
        }

        ul + h3 {
            margin-top: 5rem;
        }
    }
}

@media (max-width: 1070px) {
    .requests-container .thiscontent {
        h3 {
            height: 3rem;
            width: 50%;
            margin: 1rem 0;
            font-size: 1.5rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        button {
            height: 2.8rem;
            width: 40%;
            margin: 0.3rem 2rem;
        }
        li + li {
            margin-top: 1.5rem;
        }
    }
}

.containerUpgradeAccount {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .boxUpgradeModal {
        background-color: $secundary-color;
        width: 400px;
        height: 400px;
        border-radius: 8px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.75);
        margin: 0 1rem;
        

        .header {
            background-color: $bg-color;
            color: $secundary-color;
            text-align: center;
            border-radius: 8px 8px 0 0;
            padding: 10px 0;
            display: flex;
            flex: 1;
            justify-content: space-between;

            button {
                // display: flex;
                // flex: 1;
                // justify-content: right;
                background-color: inherit;
                border: none;
                color: $secundary-color;
                font-weight: bold;
                font-size: 1rem;
                margin-right: 1rem;
                &:hover {
                    cursor: pointer;
                }
            }

            div {
                margin-left: 1rem;
            }
        }

        .boxUpgradeForm {
            // background-color: lightgray;
            margin: 1rem;
            display: flex;
            flex-direction: column;

            label {
                margin: 0.5rem 0;
                color: $bg-color;
                font-weight: bold;
                font-size: 1.25rem;

                span {
                    color: gray;
                    font-weight: normal;
                    font-size: 1rem;
                }
            }

            input {
                height: 2rem;
                padding-left: 0.5rem;
            }

            button {
                background-color: $bg-color;
                color: $secundary-color;
                padding: 1rem 2rem;
                border-radius: 8px;
                border: none;
                align-self: center;
                margin-top: 3rem;
                font-weight: bold;
                font-size: 1.25rem;

                &:hover {
                    opacity: 0.9;
                    cursor: pointer;
                }
                &:active{
                    opacity: 0.5;
                }
            }
        }
    }
}