.company {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2%;
    border-radius: 8px;

    h1 {
        color: $bg-color;
        font-size: 1.5rem;
        font-weight: bold;
    }

    p {
        color: $grey;
        font-size: 1rem;
        width: 100%;
    }

    .home-announcement {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        h1 {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            color: $bg-color;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 0;
        }

        h2 {
            color: $bg-color;
            font-size: 1rem;
            font-weight: 400;
        }

        a {
            text-decoration: none;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5rem;
            width: 40%;
            border-radius: 10px !important;
            border: 0px;
            padding: 0.5rem !important;
            background: $bg-color;
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            transition: 0.2s;
        }

        button:hover {
            opacity: 0.5;
            cursor: pointer;
        }

        .text {
            margin: 0.5rem;
            width: 90%;
        }

        .description {
            position: relative;
            width: 90%;
            height: 60px;
            line-height: 20px;
            overflow: hidden;
            text-align: justify;
            margin-bottom: 1rem;
        }
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
                max-width: 380px;
                width: 100%;
                max-height: 250px;
                height: 100%;
                border-radius: 20px;
                object-fit: cover;
            }
        }
    }
}

@media (max-width: 800px) {
    .company h1 {
        color: $bg-color;
        font-size: 0.75rem;
        font-weight: bold;
        margin: 0;
        text-align: center;
    }
    .company p {
        // width: auto;
        margin: 0;
        margin-left: 0;
    }

    .company {
        height: 130px;
    }

    .home-announcement .text {
        visibility: hidden;
    }
    
    .home-announcement .content {
        margin: 0;
    }

    .company .home-announcement .announcement_title {
        display: none;
    }

    .company .home-announcement img {
        display: none;
    }

    .company .home-announcement .description {
        display: none;
    }

    .company .home-announcement button {
        margin: 0 auto;
        font-size: 8px;
        width: 80%;
    }

    .company .home-announcement {
        margin: 0;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 0;
    }

    .company .home-announcement .description {
        position: relative;
        width: 90%;
        height: 60px;
        line-height: 20px;
        overflow: hidden;
        text-align: justify;
        margin-bottom: 0;
    }
}

@media (max-width: 380px) {
    .company .home-announcement button {
        font-size: 1rem;
    }
    .company .home-announcement .content img {
        max-width: 300px;
        width: 100%;
        max-height: 220px;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }
}
