.profileContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profileImage {
    height: 200px;
    width: 200px;
    padding: 8px;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid white;
    cursor: pointer;
}

.profileImage:hover {
    border: 4px solid #6315ff;
}

.profileImageInput {
    visibility: hidden;
}

.profileTitle {
    width: 80%;
    margin: 1rem auto;
    font-size: 22px;
    color: #7e43f5;
    font-family: 'Roboto';
    font-weight: 600;
}

.profileInfo {
    width: 80%;
    margin: 1rem auto;
}

.profileRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        width: 95%;

        input[type="text"] {
            padding: 8px;
            width: 100%;
            font-size: 16px;
        }

        input[type="email"] {
            padding: 8px;
            width: 100%;
            font-size: 16px;
        }

        p {
            padding: 8px;
            width: 100%;
            font-size: 16px;
        }
    }
}

.profileRowSubmit {
    display: flex;
    flex-direction: row-reverse;

    button {
        padding: 8px;
        margin: 1rem;
        font-size: 16px;
    }
}

.profileRowFilter {
    display: flex;
    flex-direction: row-reverse;
    
    p {
        margin: 0 4px;
    }
}

@media (max-width: 800px) {
    .profileRow {
        flex-direction: column;

        label {
            margin: 1rem 0;
        }
    }
}

.profileActivitiesInfo {
    width: 80%;
    margin: 1rem auto;
}

.profileActivitiesRow {
    margin: 1rem;
    padding: 4px;
    background-color: rgb(232, 195, 247);
    border-radius: 8px;
}

.dateFilter {
    color : #7e43f5;
    cursor: pointer;
}

.dateFilter:hover {
    text-decoration: underline;
}