.notifications-button {
    button {
        background: transparent;
        border: 0;
        cursor: pointer;
    }

    h1 {
        position: absolute;
        right: 225px;
        top: 15px;
        background: $bg-color;
        font-size: 0.8rem;
        padding: 2px 5px 2px 5px;
        border-radius: 30px;
        color: #fff;
        z-index: 1;
    }

    .image {
        overflow: hidden;
        -webkit-animation: ring 4s 0.7s ease-in-out infinite;
        -webkit-transform-origin: 50% 4px;
        -moz-animation: ring 4s 0.7s ease-in-out infinite;
        -moz-transform-origin: 50% 4px;
        animation: ring 4s 0.7s ease-in-out infinite;
        transform-origin: 50% 4px;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 2;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #0000001a;
    }

    .modal2 {
        flex-direction: column;
        position: fixed;
        z-index: 2;
        background-color: white;
        margin-top: 10px;
        right: 10px;
        width: 30%;
        height: auto;
        max-height: 90%;
        overflow-y: auto;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 black;

        span {
            align-self: flex-end;
            color: black;
        }

        h2 {
            font-size: 1.5rem;
        }

        .notificationRow {
            border-bottom: 1px solid lightgray;
            padding: 0.5rem;
            text-decoration: none;
            color: inherit;

            &:hover {
                cursor: pointer;
                h3 {
                    text-decoration: underline;
                }
            }

            h3 {
                color: $bg-color;
            }

            p {
                margin-top: 0.25rem;
                margin-bottom: 0.5rem;
            }

            span {
                color: gray;
            }
        }

        button {
            background-color: $bg-color;
            color: white;
            width: auto;
            margin: 0;
            margin-top: 1rem;
            padding: 5px;

            &:hover {
                opacity: 0.9; 
            }

            &:disabled {
                opacity: 0.3;
            }
        }
    }

    @media (max-width: 500px) {
        .modal2 {
            width:90%;
        }
    }

    .modal-content {
        position: relative;
        background-color: #fefefe;
        border-radius: 20px;
        margin: auto;
        padding: 0;
        width: 70%;
        animation-name: animatetop;
        animation-duration: 0.5s;

        .modal-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            
            .previewNotification {
                text-decoration: none;
                color: inherit;
                width: 100%;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                text-align: left;
                
                h2 {
                    font-size: 1.5rem;
                    color: $bg-color;
                    font-weight: normal;
                    // cursor: pointer;
                    display: flex;
                }

                &:hover {
                    h2 {
                        text-decoration: underline;
                    }
                }

                p {
                    align-self: flex-start;
                    margin-left: 1rem;
                    margin-top: 0.25rem;
                }

                .previewTime {
                    color: gray;
                    align-self: flex-start;
                    margin-left: 0.25rem;
                    margin-top: 1rem;
                }

                .previewDivider {
                    height: 2px;
                    width: 95%;
                    background-color: lightgray;
                    align-self: center;
                    margin-top: 1rem;
                }
            }
        }
    }

    .modal-header {
        padding: 5px 20px 35px 0;
        background-color: $bg-color;
        color: white;
        border-radius: 20px 20px 0 0;
        text-align: center;
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }
    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@media (max-width: 500px) {
    .notifications-button {
        .modal-content {
            width: 90%;

            .modal-text h2 {
                font-size: 1.5rem;
            }
        }
        h1 {
            right: 25px;
            background: #fff;
            color: $bg-color;
        }
    }
}
