.company-announcement {
    width: 92%;
    min-height: 85%;
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem 2rem 0 2rem;
    margin-left: 6%;
    margin-right: 6%;
}

.company-announcements {
    .announcements-card {
        padding: 2rem 2rem;
        border-radius: 20px;
        margin: 1rem;
        border: 0.1px solid #e5e5e5;
    }

    p {
        color: $grey;
        font-size: 1rem;
        margin-left: 1.5rem;
        margin: 0;
    }

    p + p {
        margin-bottom: 1rem;
    }

    .announcement-options {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 0.5rem;

        button {
            width: 3%;
            margin-left: 1rem;
            border: 0;
            border-radius: 10px;
            transition: 0.7s;
            background: transparent;
        }

        button:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    /* Modal */

    .modal {
        display: none;
        position: fixed;
        z-index: 2;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #0000001a;
    }

    .modal-content {
        position: relative;
        background-color: #fefefe;
        border-radius: 20px;
        margin: auto;
        padding: 0;
        width: 50%;
        animation-name: animatetop;
        animation-duration: 0.5s;

        form .export {
            width: 100%;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            height: 50%;
            border-radius: 15px !important;
            border: 0px;
            padding: 1rem !important;
            background: $bg-color;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin: 5rem 0;
            cursor: pointer;
        }

        button:hover {
            opacity: 0.5;
        }
    }

    .modal-header {
        padding: 40px 50px;
        background-color: $bg-color;
        color: white;
        border-radius: 20px 20px 0 0;
        text-align: center;

        h2 {
            font-weight: 300;
        }
    }

    .modal-confirmation form {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        margin: 0;
        padding: 0;
        border: 0;
    }
}

@media (max-width: 800px) {
    .company-announcements {
        width: 100%;
        min-height: 85vh;
    }
    .company-announcements .modal {
        padding-top: 100px;
    }
    .company-announcements .modal-content {
        width: 90%;
    }
    .company-announcements .modal-confirmation form button {
        width: 45%;
    }
}

@media (max-width: 500px) {
    .company-announcements .content img {
        max-width: 100%;
    }
    .company-announcements .content h2 {
        margin-top: 1rem;
        max-width: 100%;
    }
    .company-announcements .announcement-options button {
        width: 3rem;
        height: 3rem;
        justify-content: space-between;
    }
}
