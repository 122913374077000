.images img {
    width: 100%;
    transition: 0.6s;
}

.images img:hover {
    opacity: 0.5;
}

.box-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 1%;
    border-radius: 8px;

    h1 {
        margin-left: 0.6rem;
        margin-bottom: 1rem;
        color: $bg-color;
        font-weight: 900;
        font-size: 2.1rem;
    }

    h2 {
        margin-top: 1rem;
        margin-left: 0.6rem;
        font-size: 1.5rem;
        font-weight: normal;
        color: $bg-color;
    }

    a {
        text-decoration: none;
    }
}

.company {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    width: 31%;
    max-height: 40rem;
    background-color: white;
    padding: 2%;
    border-radius: 8px;

    h1 {
        margin-left: 1rem;
        color: $bg-color;
        font-size: 4rem;
    }

    a {
        text-decoration: none;
    }
}

@media (max-width: 660px) {
    .box-content {
        width: 100%;
        height: 130px;

        h1 {
            font-size: 10px;
        }

        h2 {
            font-size: 8px;
        }
    }
}