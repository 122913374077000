.profile-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        max-width: 60%;
        box-sizing: border-box;
        object-fit: cover;
        border-radius: 30px;
        margin-bottom: 1rem;
    }

    a {
        color: $bg-color;
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    hr {
        width: 80%;
        margin-top: 2rem !important;
        margin: 0 2rem;
        border-top: 1px dashed #c8c8d3;
    }

    .button {
        height: 1.8rem;
        width: 20%;
        border-radius: 4px;
        border: 0px;
        background: #fddde2;
        color: #f24960;
        font-size: 0.9rem;
        font-weight: bold;
        transition: 0.2s;
    }

    .button:hover {
        opacity: 0.5;
    }

    .about {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 0.5rem;

        h2 {
            font-size: 2rem;
            color: $bg-color;
            font-weight: 900;
            margin: 0;
        }

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 1rem;
        }

        p {
            color: $grey;
            font-size: 1.3rem;
            margin-left: 0.5rem;
        }

        button {
            height: 2.6rem;
            width: 55%;
            border-radius: 4px;
            border: 0px;
            background: #deeff9;
            color: #478fc8;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin: 0.7rem 0;
        }

        button:hover {
            opacity: 0.7;
        }
    }

    .update-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        h4 {
            width: 80%;
            float: left !important;
            text-align: left !important;
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        input[type="text"] {
            align-items: center;
            width: 80% !important;
            height: 2.5rem;
            box-sizing: border-box;
            border: 1px solid $grey !important;
            border-radius: 10px;
            font-size: 16px;
            background-color: transparent !important;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            padding: 0 1rem;
        }

        h4 {
            width: 100%;
            margin-left: 2.3rem;
        }
        .options {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            /* margin: .5rem 0; */

            label {
                text-align: center;
                width: 80% !important;
                border-radius: 10px;
                font-size: 20px;
                background-color: #efefef;
                color: #4c4c4c;
                padding: 5px;
                cursor: pointer;
            }

            p {
                color: $grey;
                font-weight: bold;
                font-size: 1.5rem;
            }

            input {
                margin-top: 0.5rem;
            }

            img {
                max-width: 20%;
            }
        }

        .save {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2rem;
            border-radius: 10px !important;
            border: 0px;
            padding: 1rem !important;
            background: #00a651 !important;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin: 0.7rem 0;
            min-width: 5rem;
        }

        .save:hover {
            opacity: 0.5;
        }

        .button {
            height: 1.5rem;
            width: 20%;
            border-radius: 4px;
            border: 0px;
            background: #fddde2;
            color: #f24960;
            font-size: 0.9rem;
            font-weight: bold;
            transition: 0.2s;
            min-width: 5rem;
            margin: 0;
            transition: 0.2s;
        }

        .button:hover {
            opacity: 0.5;
        }
    }
}

.my-profile {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 1rem;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 1rem;
        margin-top: 0.5rem;

        h3 {
            font-weight: 450;
        }

        h4 {
            color: #c8c8d3;
        }

        .update-profile {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

@media (max-width: 700px) {
    .profile-box {
        justify-content: center;

        img {
            max-width: 50%;
            max-height: 100%;
            box-sizing: border-box;
            object-fit: cover;
            border-radius: 30px;
        }

        .about {
            margin-left: 0;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .my-profile button {
        margin: 0 !important;
        margin-left: 1rem !important;
        padding: 0rem !important;
        background: #fddde2 !important;
        color: #f24960 !important;
    }

    .update-options button {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        height: 3rem;
        padding: 0;
    }
}
