.box-calendar {
    img {
        max-width: 50%;
        transition: 0.6s;
    }

    img:hover {
        opacity: 0.5;
    }

    a {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $bg-color;
        padding: 1%;
        border-radius: 8px;
    }

    h1 {
        margin: 2rem 0;
        color: #efefef;
        font-weight: 400;
        font-size: 2rem;
    }

    h1 b {
        margin-left: 0.6rem;
        margin-bottom: 0.5rem;
        color: #efefef;
        font-weight: 900;
        font-size: 2rem;
    }

    a {
        text-decoration: none;
    }

    a button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        width: 40%;
        margin: 2rem 0;
        border-radius: 10px !important;
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        transition: 0.2s;
    }

    a button:hover {
        background: #fff;
        color: $bg-color;
        cursor: pointer;
    }
}

@media (max-width: 660px) {
    .box-calendar {
        width: 100%;
        height: 130px;

        h1 {
            margin: 0;
            font-size: 11px;
        }

        h1 b {
            margin: 0;
            font-size: 11px;
        }

        img {
            max-width: 40%;
            margin: 1rem;
        }

        a button {
           width: 90%;
           margin: 0;
        }
    }
}
