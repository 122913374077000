.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  margin: 0;
  padding: 0;
  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.1);


  width: 4%;
  font-weight: bold;
  background-color: $light;
  position: fixed;
  height: 100%;

  p {
    display: none;
  }

  div.short {
    cursor: pointer;
  }

  span {


    svg {
      display: none;
    }
  }


  transition: .4s;
}

div.ask-fegpt {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  button.ask-fegpt {
    background-color: purple;
    color: white;
    padding: 12px 32px;
    border-radius: 20px;
    margin-top: 18px;
    border: none;
    cursor: pointer;
  }
}


.sidebar div.short {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin: 0;

}

.sidebar div.short:hover {
  color: $bg-color;
  background-color: $secundary-color;
}

.sidebar div.spacing {
  display: none;
}

.sidebar .active {
  color: $bg-color;
  background-color: $secundary-color;
}






//////////////////////////////// ACTIVE SIDEBAR
.sidebar-active {
  width: 100%;
  background-color: transparent;
  height: 100%;
  z-index: 9999;
  display: grid;
  grid-template-columns: 240px auto;
  position: fixed;
  border-bottom: 1px solid $secundary-color;

  p {
    display: block;
  }

  span {
    width: 100%;

    display: flex;
    justify-content: space-between;

    align-items: center;

    svg {
      cursor: pointer;
      display: flex;
      color: $bg-color;
      padding: 8px;
      border-radius: 8px;
      width: 40px;
      height: 40px;

    }
  }

  hr {
    width: 1000px;
    height: 30px;
    border: none;
    background: $secundary-color;
    border-radius: 18px;
  }
}

.sidebar-active div.spacingT {
  width: 100%;
  height: 100%;
  background-color: transparent;
  
}


.sidebar-active div.content {
  width: 100%;
  height: 100%;
  background-color: $light;
  overflow: scroll;
  overflow-x: hidden;
}

.sidebar-active div.short {
  display: flex;
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid $secundary-color ;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  border-radius: 0px;

  label {
    width: 3px;
    height: 30px;
    margin: 0;
    padding: 0;
    border-radius: 120px;

  }

  label.act {
    background-color: $bg-color;
  }



}

.sidebar-active .down {
  background-color: white;

  label.act {
    background-color: $grey;

  }
}



.sidebar #monograma {
  width: 155px;
  margin: 1rem;

}

.sidebar #monograma.actived {
  width: 45px;
  margin: 1rem;
}




@media screen and (max-width: 700px) {
  #config {
    display: block;
    position: relative;
    height: 5rem;
  }
}