.management-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 7%;
    margin-left: 9%;
}

.management-top {
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: space-between;
}

.top-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    input[type="text"] {
        width: 13rem;
        margin-left: 2rem;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        background-color: #efefefff;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 0.4rem 3rem 0.4rem 1rem;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
    }

    input[type="text"]:focus {
        width: 60%;
        background-color: #efefef;
    }

    h1 b {
        color: gray;
        font-size: 1.5rem;
        margin-left: 0.3em;
    }
}

.top-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
        height: 2.6rem;
        width: 50%;
        border-radius: 8px;
        border: 0px;
        background: #25ab9f;
        color: $secundary-color;
        font-size: 1rem;
        font-weight: bold;
        transition: 0.2s;
    }

    button+button {
        height: 2.6rem;
        width: 10%;
        border-radius: 8px;
        border: 0px;
        margin: 0 1rem;
        background: transparent;
        color: #25ab9f;
        transition: 0.2s;
    }
}

.users-management {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: $secundary-color;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;

    .users {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .users td,
    .users th {
        width: 12%;
        border-bottom: 1px solid #ddd;
        padding: 8px;
    }

    .users tr:hover {
        background-color: #ddd;
    }

    .users th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: black;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .users td img {
        max-width: 40%;
        min-width: 35%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .users td button:disabled {
        opacity: 0.5;
    }

    #image {
        height: 4rem;
        padding: 0;
    }

    .users td b {
        display: flex;
        justify-content: center;
        border-radius: 8px;
        padding: 1rem;
        color: $secundary-color;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
    }

    .users td button {
        height: 2.6rem;
        border-radius: 8px;
        padding: 0.5rem;
        background: #efefef;
        color: $secundary-color;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
        border: 0px;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    .list-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    .list-header h1 {
        font-weight: bolder;
    }

    .list-header select {
        background: #ebdeee;
        width: 8rem;
        font-weight: bold;
        font-size: 1rem;
        padding: 1rem 0.7rem;
        color: $bg-color;
        margin-right: 1rem;
        border: 0px;
        border-radius: 5px;
    }

    .list-header select::-ms-expand {
        display: none;
    }

    .list-header select option {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .list-header button {
        background: $bg-color;
        width: 8rem;
        font-weight: bold;
        font-size: 1rem;
        padding: 1rem 0.7rem;
        color: $secundary-color;
        margin-right: 1rem;
        border: 0px;
        border-radius: 5px;
    }

    /* 
    .list-header .MuiFormControl-root{
        width: 50%;
    } */

    .label-mobile {
        display: none;
    }

    td button:hover {
        opacity: 0.5;
    }
}

.second-list-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    margin-top: 10px;
    width: 100%;

    .select-filter-user {
        width: 200px;
    }

    .align-vertical {
        height: 38px;
        margin-top: 0;
    }
}

/*Modal*/

.users-management .modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #0000001a;
}

.users-management .modal-header {
    padding: 40px 50px;
    background-color: $bg-color;
    color: $secundary-color;
    border-radius: 20px 20px 0 0;
}

.users-management .modal-content {
    position: relative;
    background-color: #fefefe;
    border-radius: 20px;
    margin: auto;
    padding: 0;
    width: 50%;
    animation-name: animatetop;
    animation-duration: 0.5s;

    .modal-options {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .modal-options button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48%;
        border-radius: 10px !important;
        border: 0px;
        padding: 1rem !important;
        background: $bg-color;
        color: $secundary-color;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0 0 0;
        transition: 0.2s;
        cursor: pointer;
    }

    .modal-options button:disabled {
        color: $bg-color;
        background: $secundary-color;
        border: 1px solid $bg-color;
        cursor: default;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        border: 0;
    }

    form .MuiFormControl-root {
        width: 100%;
        margin: 1rem 0;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        height: 50%;
        width: 30%;
        border-radius: 10px !important;
        border: 0px;
        padding: 1rem !important;
        background: $bg-color;
        color: $secundary-color;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
        margin: 3rem 0;
        cursor: pointer;
    }

    button:hover:enabled {
        opacity: 0.5;
    }

    p {
        color: $grey;
    }
}

.modalRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 4px auto;
    width: 90%;

    .basic-multi-select {
        width: 100%;
        margin: 0 4px;
    }
}

.users-management .modal-confirmation form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
}

.deletebtn {
    background: gray !important;
}

@keyframes animatetop {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.close {
    color: $secundary-color;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 4px 16px;
    background-color: $bg-color;
    color: $secundary-color;
}

.modal-header h2 {
    color: $secundary-color;
}

.management-content .buttons-topbar button {
    background-color: $secundary-color;
    border: none;
    border-radius: 6px;
    font-size: 22px;
    color: $grey;
    margin: .8rem;
    padding: .6rem;
}

.management-content .buttons-topbar button.active {
    background-color: $bg-color;
    color: $secundary-color;
}

.management-content .buttons-topbar button:hover {
    cursor: pointer;
}

.management-content .users-management .pagination {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        height: 40%;
        width: 15%;
        border-radius: 10px !important;
        border: 0px;
        padding: 0.5rem !important;
        background: $bg-color;
        color: $secundary-color;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
        margin: 3rem 0;
    }

    button:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    button:disabled {
        color: $secundary-color;
        background-color: $grey;
        cursor: unset;
        opacity: 0.7;
    }
}

@media (max-width: 800px) {
    #image {
        display: none;
    }

    .management-content {
        margin-right: 2%;
        margin-left: 5%;
    }

    .modalRow {
        flex-direction: column;

        .basic-multi-select {
            margin: 4px 0;
        }
    }
}

@media only screen and (max-width: 760px) {
    .modalRow {
        flex-direction: column;
    }



    .users-management {

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            width: 100% !important;
            margin: 0;
        }

        th {
            display: none;
        }
    }

    .label-mobile {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 700px) {

    .modalRow {
        flex-direction: column;
    }



    .management-content {
        margin-right: 2%;
        margin-left: 2%;
    }

    .management-top {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: space-between;
    }

    .top-left {
        input[type="text"] {
            width: 15rem;
            margin: 1rem 0;
        }

        input[type="text"]:focus {
            width: 18rem;
        }

        h1 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    .users-management div select,
    button {
        margin-right: 0;
        margin-left: 2rem;
    }
}

@media (max-width: 550px) {
    .list-header {
        margin: 0;
    }

    .export {
        margin-top: 1rem;
    }

    .management-content .users-management {
        .pagination button {
            width: 35%;
            padding: 1rem;
        }

        .modal-content {
            width: 90%;
        }
    }

    .modalRow {
        flex-direction: column;
    }


}