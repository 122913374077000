.HomeContent {
    display: flex;
    width: 80% !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem 2rem;
    max-height: 100vh;
    margin-left: 12%;
    margin-right: 8%;
}

.search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 2rem;

    input[type="text"] {
        width: 80%;
        height: 2.5rem;
        box-sizing: border-box;
        border: 1px solid $bg-color;
        border-radius: 10px;
        font-size: 16px;
        background-color: transparent;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 12px 20px 12px 10px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 1s ease-in-out;
    }

    input[type="text"]:focus {
        width: 100%;
        background-color: white;
    }
}

.link-modal {
    color: $bg-color;
    font-weight: bold;
    // margin-left: 5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

.full {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 100% !important;
    }
}

.wanted {
    width: 50%;
}

@media (max-width: 700px) {
    .HomeContent {
        margin-left: 8%;
    }

    .wanted {
        width: 100%;
    }
}

.tooltip {
    width: 60%;
    height: auto;
    max-height: 80%;
    background-color: white;
    position: fixed;
    z-index: 3;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px 1px black;
    align-items: center;
    top: 80px;

    h2 {
        color: $bg-color;
        margin: 1rem;
    }

    p {
        margin: 0 2rem;
        color: $bg-color;

        span {
            font-weight: bold;
        }
    }

    .video {
        margin: 2rem 1rem;
        width: 90%;
        height: 500px;
    }
}

.tooltipButton {
    background-color: $bg-color;
    padding: 0.5rem 1rem;
    border: none;
    margin: 1rem 0;
    border-radius: 8px;
    color: $secundary-color;
    font-weight: bold;
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

.tooltipStep {
    width: 30rem;
    height: 20rem;
    left: 100px;
    top: 50px;
    overflow: visible;
    display: flex;
    justify-content: space-between;

    h2 {
        font-size: 1.5rem;
        text-align: center;
    }

    h2+h2 {
        margin-top: -10px;
    }

    p {
        max-width: 300px;
        margin: 1rem;
        text-align: justify;
    }
}

.stepHome {
    top: 50px;
    left: 100px;
}

.stepAnnouncement {
    top: 105px;
    left: 100px;
}

.stepUserValidation {
    top: 165px;
    left: 100px;
}

.stepUserManagement {
    top: 220px;
    left: 100px;
}

.stepNewAnnouncement {
    top: 275px;
    left: 100px;
}

.stepLocations {
    top: 330px;
    left: 100px;
}

.stepCalendar {
    top: 390px;
    left: 100px;
}

.stepAnalytics {
    top: 320px;
    left: 100px;
}

.stepShortcuts {
    top: 425px;
    left: 100px;
}

.stepPermissions {
    top: 480px;
    left: 100px;
}

.position {
    width: 40px;
    height: 40px;
    background-color: $secundary-color;
    position: absolute;
    rotate: 45deg;
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.5);
    top: 35px;
    left: -20px;
}

.posDown {
    top: initial;
    left: -20px;
    bottom: 35px;
}

@media (max-width: 700px) {
    .tooltip {
        width: 90%;
        max-height: 85%;
        top: 100px;
        left: 5%;

        .video {
            height: 200px;
        }
    }

    .position {
        box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.5);
        top: -1.3rem;
        left: 34%;
    }

    .tooltipStep {
        height: 22rem;
    }
}

.active {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.desactive {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}