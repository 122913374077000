.specialEventContainer {
  margin-top: 40px;
  margin-left: 7%;
  padding-bottom: 22px;

  h2 {
    font-size: 24px;
    color: $bg-color;
    margin-bottom: 1rem;
  }
}

.specialEventInfo {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-bottom: 3rem;
}

.specialEventInfoRow {
  width: 400px;
  max-width: 400px;
  margin-right: 100px;

  section {
    display: flex;

    p {
      margin: 0;
    }
  }

  input {
    padding: 4px;
    margin: 4px 0 2rem;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    margin-bottom: 2rem;
  }

  div {
    width: 400px;
  }

  div textarea {
    margin: 8px 0 2rem;
    padding: 4px;
    width: 400px;
    resize: none;
  }
}

.specialEventEditButton {
  cursor: pointer;
}

.specialEventTitle {
  font-size: 24px;
  color: $bg-color;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  width: 700px;
  background-color: inherit;
  max-width: 750px;
}

.specialEventUpdateButton {
  padding: 6px 24px;
  background-color: $bg-color;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.specialEventUpdateButton:hover {
  opacity: 0.8;
}

.specialEventUpdateButton:disabled {
  background-color: #b5b5b5;
  cursor: default;
}

.specialEventDeleteButton {
  padding: 6px 24px;
  background-color: #f54343;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.specialEventDeleteButton:hover {
  background-color: #f87575;
}

.specialEventInfoMultiSelectRow {
  width: 100%;
  max-width: 100%;

  p {
    font-size: 18px;
    margin-bottom: 2rem;
  }
}

.followersButton {
  display: flex;
  justify-content: space-between;
}

.specialEventMultiSelect {
  width: 100%;
  padding: 4px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 2;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #0000001a;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  border-radius: 20px;
  margin: auto;
  padding: 0;
  width: 50%;
  animation-name: animatetop;
  animation-duration: 0.5s;

  form .export {
    width: 100%;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 50%;
    border-radius: 15px !important;
    border: 0px;
    padding: 1rem !important;
    background: $bg-color;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    transition: 0.2s;
    margin: 5rem 0;
  }

  button:hover {
    opacity: 0.5;
  }

  .deletebtn button {
    background-color: gray;
    cursor: pointer;
  }
}

.modal-header {
  padding: 40px 50px;
  background-color: $bg-color;
  color: white;
  border-radius: 20px 20px 0 0;
  text-align: center;

  h2 {
    font-weight: 300;
  }
}

.modal-confirmation form {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
  border: 0;
}

.specialEventNewCommentContainer {
  width: 90%;

  textarea {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgb(53, 53, 53);
    padding: 6px;
    outline: none;
    resize: none;
  }
}

.specialEventNewCommentActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  label {
    margin-right: 8px;
  }

  button {
    padding: 6px 18px;
    background-color: $bg-color;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  button:hover {
    opacity: 0.8;
  }
}

.specialEventComment {
  margin: 2rem 0;
  background-color: white;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 10px;
}

.specialEventCommentData {
  max-width: 70%;
  display: flex;
  flex-direction: row;

  span {
    margin-right: 4px;
  }

  textarea {
    width: 100%;
    border-radius: 8px;
    padding: 6px;
    border: 0px solid rgba(53, 53, 53, 0.267);
    outline: none;
    resize: none;
    margin-right: 4px;
  }
}

.specialEventCommentMetadata {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    background-color: limegreen;
    color: white;
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-radius: 6px;
    margin-right: 4px;
    cursor: pointer;
    padding: 4px;
  }

  img {
    margin-right: 2px;
  }
}

.specialEventCommentsSection {
  display: flex;
  flex-direction: column-reverse;
}