.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  box-shadow:-10px -10px 10px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  background-color: $secundary-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 25px;
  padding-right: auto;

  .myUserNavbarModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    button {
      width: 95%;
      padding: 6px;
      background-color: $secundary-color;
      cursor: pointer;
      font-weight: 600;
      margin: 4px 0;
    }

    button:hover{
      background-color: $bg-color;
    }
    
    
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 1.3rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: black;
    font-weight: 500;
  }

  .closebtn {
    position: absolute;
    right: -30px;
    font-size: 36px;
    margin-right: 50px;
  }

  .closebtn {
    color: $bg-color !important;
    cursor: pointer;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;

    a {
      font-size: 18px;
    }
  }
}
