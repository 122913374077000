.calendar-app {
  width: 90%;
  margin-left: 6%;

  /*Modal*/

  .modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #0000001a;
  }

  .modal-header {
    padding: 4px 16px;
    background-color: $bg-color;
    color: white;
    border-radius: 20px 20px 0 0;
  }

  .modal-body {
    z-index: 2;
    position: relative;
    border-radius: 20px;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 50%;
    animation-name: animatetop;
    animation-duration: 0.5s;

    form {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0;
      border: 0;
    }

    form .export {
      width: 100%;
    }

    button {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 40%;
      border-radius: 10px !important;
      border: 0px;
      padding: 1rem !important;
      background: $bg-color;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      transition: 0.2s;
      margin: 0.7rem 0;
      cursor: pointer;
    }

    button:hover {
      opacity: 0.5;
    }

    button:disabled {
      background: $grey !important;
      opacity: 0.5;
    }

    .groupsSection button {
      width: 21%;
    }
  }

  .modal-confirmation form {
    z-index: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .groupsSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;

    button {
      width: auto;
    }
  }

  .MuiFormControl-root {
    width: 90%;
  }

  .modal-box .basic-multi-select {
    width: 90%;
  }
}

.fc-button {
  background-color: $bg-color !important;
  border: 1px solid $bg-color !important;
  color: #efefef;
}

.fc-button-active {
  background-color: #306090 !important;
}

.calendar-content {
  display: grid;
  grid-template-columns: 15% auto;

  .demo-app-sidebar {
    button {
      transition: 0.5s;
    }

    button:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.list-itens {
  color: #efefef;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 1rem;
}

.more-info-button {
  color: #efefef;
  padding: 0.5rem;
  border: 0;
  border-radius: 10px;
  margin: 1rem;
  background-color: $bg-color;
}

.sidenav {
  z-index: 2;
}

.fc-daygrid-dot-event {
  display: block;
  align-items: center;
  padding: 2px 0;
}

.fc-daygrid-event-harness i {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 800px) {
  .demo-app-sidebar {
    display: none;
  }

  .calendar-content {
    display: block;
  }

  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .fc-button {
    display: flex;
    margin: 0.1rem !important;
  }

  .calendar-app {
    width: 100%;
    height: 85vh;
    margin: 0;
  }

  .demo-app-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.1rem;
  }

  .fc-view-harness {
    width: 100%;
  }

  .calendar-app .modal .modal-body {
    width: 90%;

    button {
      font-size: 0.8rem;
    }

    .modal-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .fc-media-screen {
    width: 100%;
    height: 60vh;
  }
}

@media (max-width: 700px) {
  .demo-app-main {
    margin-left: 0;
  }
}