.user-validation {
    display: flex;
    flex-direction: column;
    margin-left: 6%;
    margin-top: 2rem;

    img {
        width: 10%;
        box-sizing: border-box;
        border-radius: 20px;
    }

    .description {
        margin-top: 2em;
        align-items: center;

        h2 {
            font-weight: bold;
        }

        h2 + h2 {
            margin-top: 2rem;
        }

        b {
            text-align: left;
            border-radius: 10px;
            justify-content: left;
            font-weight: normal;
        }

        p {
            margin-top: 0.5rem;
            color: #b6b6ba;
        }

        .basic-multi-select {
            width: 60%;
            margin-top: 1rem;
            color: black;
            font-weight: bold;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 3rem;

        button {
            height: 3rem;
            width: 15%;
            border-radius: 20px;
            border: 0px;
            color: #fff;
            font-size: 1.5rem;
            font-weight: 900;
            transition: 0.2s;
            margin: 0.7rem 0;
        }

        button:hover {
            opacity: 0.6;
        }

        button + button {
            margin-left: 1rem;
        }
    }
}

@media (max-width: 700px) {
    .user-validation .actions button {
        height: 4rem;
        width: 45%;
    }
    .user-validation .description b {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5rem 1rem;
    }
    // .user-validation .user-validation img {
    //     width: 30%;
    // }
    .user-validation {
        margin: 0 1rem;
    }
}
