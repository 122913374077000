.company-announcement {
    p {
        color: $grey;
        font-size: 1rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        h1 {
            color: $bg-color;
            font-size: 1.9rem;
            font-weight: bold;
            margin: 1rem 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: $bg-color;
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 1rem;
            max-width: 80%;
        }

        a {
            text-decoration: none;
        }

        img {
            max-width: 30%;
            height: auto;
            border-radius: 20px;
            /* object-fit: cover; */
        }
    }
}

.rate-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
        border: 0.5px dotted #eaeaea;
        width: 100%;
        margin-bottom: 1rem;
        margin-top: 4rem;
    }

    h3 {
        color: $grey;
        font-weight: inherit;
        margin-bottom: 0.5rem;
    }

    .center-emoji {
        position: relative;
        display: flex;
        width: 100%;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        .emoji-mart-emoji span {
            transition: 0.5s;
        }

        .emoji-mart-emoji span:hover {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .center-emoji + hr {
        margin-top: 1rem;
    }
}

@media (max-width: 800px) {
    .company-announcement {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

@media (max-width: 500px) {
    .company-announcement .content {
        img {
            max-width: 100%;
        }
        h2 {
            margin-top: 1rem;
            max-width: 100%;
        }
    }
}
