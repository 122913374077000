.new-announcement {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 7%;
    margin-right: 4%;
    width: 90%;
    height: 100%;
    background: white;
    border-radius: 20px;

    h1 {
        font-weight: bold;
        color: $bg-color;
        margin-left: 2rem;
        margin-top: 1rem;
    }

    .utils {
        margin-left: 2rem;

        input[type="file"] {
            display: none;
        }

        .imageInput label {
            cursor: pointer;
            align-items: center;
            border-radius: 10px;
            font-size: 20px;
            background-color: #efefef;
            color: #4c4c4c;
            padding: 7px 150px;
        }

        .imageInput p {
            color: $grey;
            margin: 0.7rem 0 0 0.2rem;
        }

        textarea {
            align-items: center;
            width: 50%;
            min-height: 20rem;
            box-sizing: border-box;
            border: 1px solid transparent;
            border-radius: 10px;
            font-size: 16px;
            background-color: #efefef;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            padding: 5px 20px 12px 40px;
        }

        h4 {
            margin: 1rem 0;
        }
    }

    .utils input[type="text"],
    .typefile {
        align-items: center;
        width: 50%;
        height: 2.5rem;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 10px;
        font-size: 16px;
        background-color: #efefef;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 5px 20px 8px 10px;
    }

    button {
        width: 15%;
        border-radius: 10px !important;
        border: 0px;
        padding: 1rem;
        background: $bg-color;
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
        margin: 0.7rem 0;
        margin-left: 2rem;
    }

    button:hover {
        cursor: pointer;
        opacity: 0.5;
    }
}
.create-announcement {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 2rem;
}

.create-announcement-everyone {
    align-self: flex-start;
}

.specific-groups {
    width: 22%;

    .MuiFormControl-root {
        width: 40%;
    }
}

.MuiFormControlLabel-root {
    margin-top: 16px;
}

.new-announcement .modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #0000001a;
}

.new-announcement .modal-content {
    position: relative;
    background-color: #fefefe;
    border-radius: 20px;
    margin: auto;
    padding: 0;
    width: 50%;
    animation-name: animatetop;
    animation-duration: 0.5s;

    form .export {
        width: 100%;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        height: 50%;
        border-radius: 15px !important;
        border: 0px;
        padding: 1rem !important;
        background: $bg-color;
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
        transition: 0.2s;
        margin: 5rem 0;
    }

    button:hover {
        opacity: 0.5;
    }

    .deletebtn button {
        background-color: gray;
        cursor: pointer;
    }
}

.new-announcement .modal-header {
    padding: 40px 50px;
    background-color: $bg-color;
    color: white;
    border-radius: 20px 20px 0 0;
    text-align: center;

    h2 {
        font-weight: 300;
    }
}

.new-announcement .modal-confirmation form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
}

.rdw-editor-wrapper {
    width: 50%;
}

.rdw-editor-main {
    background: #efefef;
    border-radius: 10px;
    border: 1px solid transparent;
    min-height: 150px;
}

.rdw-link-modal,
.rdw-embedded-modal {
    min-height: 250px;
}

.rdw-image-modal-btn,
.rdw-embedded-modal-btn,
.rdw-link-modal-btn {
    margin: 0 5px;
    width: 100% !important;
    height: 30px;
    border: 1px solid #f1f1f1;
    margin-left: 0 !important;
    border-radius: 2px;
    padding: 0 !important;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

@media (max-width: 700px) {
    .new-announcement {
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 1rem;
        width: 100%;

        h1 {
            margin: 0;
        }

        button {
            margin: 0;
            width: 80%;
        }

        .utils {
            input[type="file"] {
                width: 30%;
            }
            textarea {
                width: 90%;
            }
            input[type="file"] {
                width: 90%;
            }
        }
    }

    .create-announcement {
        width: 90%;
        margin: 0;
    }

    .new-announcement .utils input[type="text"],
    .typefile {
        width: 90%;
    }

    .specific-groups {
        margin-left: 1rem;
        margin-top: 0;
        width: 100%;
    }

    .rdw-editor-wrapper {
        width: 100%;
    }

    .rdw-editor-main {
        margin: 1rem 0;
    }

    .rdw-image-modal-btn,
    .rdw-embedded-modal-btn,
    .rdw-link-modal-btn {
        margin-left: 0 !important;
        width: 75px;
        height: 30px;
        border: 1px solid #f1f1f1;
        border-radius: 2px;
        padding: 0 !important;
        cursor: pointer;
        background: white;
        text-transform: capitalize;
    }
}

@media (max-width: 500px) {
    .new-announcement .modal-content {
        width: 90%;
    }

    .new-announcement .modal {
        padding-top: 60px;
    }

    .new-announcement .utils {
        margin: 0;

        label {
            font-size: 15px;
        }
    }
}
