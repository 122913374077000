.paylocity-mainContainer {
    height: 100%;
    max-height: max-content;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 12px;
    }

    .monograma {
        height: 48px;
        width: auto;
        margin-bottom: 1rem;
    }

    .container {
        padding: 1.25rem;
        border-radius: 8px;
        background-color: rgb(255, 255, 255);
        width: 80%;
        height: 100%;

        p {
            color: #808080;
        }

        .header {

            h3 {
                text-align: center;
                margin-top: 22px;
                color: #808080;
            }

            h2 {
                font-size: 26px;
                text-align: center;
                margin: 22px 0;
                color: $bg-color;
            }
        }

        .attachment {
            margin: 8px 0;
            height: 80px;
            width: auto;

        }

        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    }
}