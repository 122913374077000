.background {
    width: 90%;
    margin: 1rem auto;
    background-color: rgb(255, 255, 255);
    padding: 2rem;
}

.container {
    width: 95%;
    margin: 0 auto;
    padding: 0 2rem;
}

h2 {
    font-size: 2rem;
    color: $bg-color;
    font-weight: normal;
    font-weight: 600;
}

.searchAndAddRow {
    margin: 1.25rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.paginationSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.25rem 0 0;

    .paginationDetails p {
        font-size: 14px;
        font-weight: 300;
    }
}

@media (max-width: 800px) {
    .searchAndAddRow {
        flex-direction: column;
        justify-content: space-around;

        .searchLocation {
            margin: 1.25rem 0;
        }
    }

    .modal .modal-content {
        width: 90%;
    }

    .modal-form .modal-row {
        flex-direction: column;
    }

    .modal-form .modal-row .textField {
        margin: 4px 0;
    }

    .modal-form .modal-row .googleMapsInput {
        margin: 4px 0;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #0000001a;
}

.modal-content {
    position: relative;
    background-color: #fefefe;
    border-radius: 20px;
    margin: auto;
    padding: 0;
    width: 50%;
    animation-name: animatetop;
    animation-duration: 0.5s;
}

.modal-header {
    padding: 40px 50px;
    background-color: $bg-color;
    color: white;
    border-radius: 20px 20px 0 0;
    text-align: center;
}

.modal-form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.modal-row {
    width: 75%;
    margin: 12px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.googleMapsInput {
    width: 45%;
}

.googleMapsInput .textField {
    width: 100%;
}

.textField {
    width: 45%;

    label {
        font-size: 12px;
    }
}

.modal-submit {
    width: 75%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}