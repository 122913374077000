.Analytics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f0f0f5;

    button {
        padding: 0.8rem 2rem;
        border: 2px solid #fff;
        background-color: #f0f0f5;
        color: black;
        cursor: pointer;
        font-size: 1rem;
    }

    button:disabled {
        background: #fff;
        border: 0;
        cursor: not-allowed;
    }
}

#analytics-management {
    padding: 0 0 1rem 0;
    border-radius: 1rem;

    .tables {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;

        .list-header {
            display: flex;
            flex-direction: column;
            width: 20%;

            .MuiFormLabel-root {
                font-size: 1.2rem;
            }
        }

        td,
        th {
            width: 16%;
        }

        td b {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            color: black;
            font-weight: bold;
            padding: 0;
        }

        td select {
            background: #ebdeee;
            width: 8rem;
            font-weight: bold;
            font-size: 1rem;
            padding: 1rem 0.7rem;
            color: $bg-color;
            border: 0px;
            border-radius: 5px;
        }
    }
}


.user-report-analytics {
    display: flex;
    justify-content: center;
    gap: 1rem;

    margin: 2rem 0;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        span {
            font-size: 2rem;
            font-weight: bold;
        }
    }

    button {
        padding: 1rem;
        border-radius: 32px;
        border: none;
        color: white;
        font-weight: bold;
    }

    #approved {
        background-color: #34AB9F;
    }

    #disabled {
        background-color: #B20A0A;
    }
}

.approved-status {
    background-color: #34AB9F;
    margin: 0 5rem;
    color: white;
    padding: 0.25rem 0;
    border-radius: 16px;

}

.disabled-status {
    background-color: #B20A0A;
    margin: 0 5rem;
    color: white;
    padding: 0.25rem 0;
    border-radius: 16px;
}

@media (max-width: 700px) {
    .Analytics button {
        margin: 0;
    }
    #analytics-management .tables .list-header {
        width: 50%;
    }
}
