.announcements {
    display: flex;
    flex-direction: column;
    margin-left: 6%;
    justify-content: center;
    align-items: center;
}

.announcements-sidebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin-bottom: 2rem; */
    width: 100%;

    button {
        font-size: 0.9rem;
        background-color: transparent;
        border: 0;
        background: $bg-color;
        padding: .8rem;
        border-radius: 10px;
        transition: 0.7s;
    }

    button:hover {
        opacity: 0.8;
        color: #2d2d2d;
        cursor: pointer;
    }

    button+button {
        margin-left: 1rem;
    }
}

.announcements-content {
    /* background-color: #fff;
    padding: 2rem; */
    border-radius: 10px;
    width: 92%;
    height: 100%;
    margin-right: 3%;

    h1 {
        color: $bg-color;
    }

    a {
        text-decoration: none;
    }
}

@media (max-width: 800px) {
    .announcements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 6%;
    }

    .announcements-sidebar {
        margin-right: 0;
        justify-content: center;

        button {
            margin: 0.2rem 0;
        }

        button {
            text-align: center;
        }

        button+button {
            margin-left: 0;
        }
    }

    .announcements-content {
        width: 100%;
        margin-right: 2%;
        margin-top: 1rem;
    }
}

@media (max-width: 700px) {
    .announcements {
        margin: 0 2rem;
    }

    .announcements-sidebar {
        margin-bottom: 0;

        button {
            margin: 0.2rem 0;
        }
    }
}